<template>
  <div class="h-100 position-lg-absolute">
    <h4 class="profile-divider with-divider blue f-w-400">
      {{ $t("profile.account_security") }}
    </h4>
    <div class="form-settings pb-0 pt-3 shadow-sm">
      <be-form-error v-if="error" :message="error" />
      <p class="title pb-2 text-muted">
        {{ $t("profile.account_2fa_title").toUpperCase() }}
      </p>
      <div class="pb-3">
        <p class="text text-muted">
          {{
            $t(
              `profile.${
                haveMFAEnabled ? "account_2fa_enabled_text" : "account_2fa_text"
              }`
            )
          }}
          <template v-if="haveMFAEnabled">
            <br />
            <a href="#" @click.prevent="regenerateCodes">
              {{ $t("profile.account_2fa_enabled_code_btn") }}
            </a>
            <div class="card-header" v-if="recoveries.length">
              <code
                v-for="(recovery, index) in recoveries"
                :key="`recovery-${index}`"
              >
                {{ recovery }}
              </code>
            </div>
            <span
              class="float-right f-w-600 text-muted"
              v-if="recoveries.length"
            >
              {{ $t("profile.copy_recovery_codes") }}
            </span>
          </template>
        </p>
        <button
          v-if="haveMFAEnabled"
          class="be-btn secondary danger md m-r-10"
          @click.prevent="disabled2FA"
        >
          {{ $t("profile.remove_2fa_btn") }}
        </button>
        <button
          v-else
          class="be-btn secondary green md m-r-10"
          @click.prevent="$router.push({ name: 'formPassword.mfa' })"
        >
          {{ $t("profile.account_2fa_btn") }}
        </button>
      </div>
    </div>
    <form class="form" @submit.prevent="handlePasswordChange">
      <be-form-error v-if="error" :message="error" />
      <div class="form-container h-100 p-3 pt-4 overflow-auto">
        <p class="title pb-3 text-muted">
          {{ $t("profile.change_password").toUpperCase() }}
        </p>
        <div class="be-row">
          <div class="be-col">
            <div>
              <BaseInputPassword
                v-model="user.old_password"
                :errorMessage="oldPassErrorMessage"
                :isInvalid="
                  $v.user.old_password.$error ||
                    errors.old_password !== undefined
                "
                :label="$t('profile.actual_password')"
                :placeholder="$t('profile.insert_password_placeholder')"
                @blur="$v.user.old_password.$touch()"
              ></BaseInputPassword>
            </div>
          </div>
        </div>
        <div class="be-row">
          <div class="be-col">
            <div>
              <BaseInputPassword
                v-model="user.password"
                :errorMessage="newPassErrorMsg"
                :isInvalid="
                  $v.user.password.$error || errors.password !== undefined
                "
                :label="$t('profile.new_password')"
                :placeholder="$t('profile.insert_new_password_placeholder')"
                @blur="$v.user.password.$touch()"
              >
              </BaseInputPassword>
            </div>
          </div>
          <div class="be-col">
            <div>
              <BaseInputPassword
                v-model="user.password_confirmation"
                :errorMessage="confirmPassErrMsg"
                :isInvalid="$v.user.password_confirmation.$error"
                :label="$t('profile.confirm_new_password')"
                :placeholder="$t('profile.insert_confirm_password_placeholder')"
                @blur="$v.user.password_confirmation.$touch()"
              >
              </BaseInputPassword>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container">
        <div class="divider"></div>
        <BaseButton
          :disabled="$v.$invalid || !editing"
          :loading="loading"
          :show-loading="true"
          buttonClass="btn-secondary"
        >
          {{ $t("profile.change_password") }}
        </BaseButton>
      </div>
    </form>
    <div v-if="isMFAOpen" class="bg-secondary">
      <router-view />
    </div>
  </div>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import BaseButton from "@/components/common/BaseButton.vue";
import { mapGetters } from "vuex";

export default {
  name: "FormPassword",
  components: { BaseButton },
  computed: {
    ...mapGetters("auth", ["haveMFAEnabled"]),
    isMFAOpen() {
      return this.$route.name === "formPassword.mfa";
    },
    oldPassErrorMessage() {
      if (this.$v.user.old_password.$error || this.errors.old_password) {
        if (!this.$v.user.password.required) {
          return this.$t("profile.required.actual_password");
        }
        if (this.errors.old_password) {
          return this.errors.old_password[0];
        }
      }
      return "";
    },
    newPassErrorMsg() {
      if (this.$v.user.password.$error || this.errors.password) {
        if (!this.$v.user.password.required) {
          return this.$t("profile.required.new_password");
        }
        if (!this.$v.user.password.minLength) {
          return this.$t("profile.required.password_length_6");
        }
        if (!this.$v.user.password.containsUppercase) {
          return this.$t("profile.required.at_least_one_uppercase");
        }
        if (!this.$v.user.password.containsNumber) {
          return this.$t("profile.required.at_least_one_number");
        }
        if (this.errors.password) {
          return this.errors.password[0];
        }
      }
      return "";
    },
    confirmPassErrMsg() {
      if (this.$v.user.password_confirmation.$error) {
        if (!this.$v.user.password_confirmation.required) {
          return this.$t("profile.confirm_new_password");
        }
        if (!this.$v.user.password_confirmation.sameAsPassword) {
          return this.$t("profile.password_not_matches");
        }
      }
      return "";
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.editing = true;
      },
    },
  },

  data() {
    return {
      loading: false,
      loading2FA: false,
      editing: true,
      success: null,
      errors: [],
      recoveries: [],
      error: null,
      user: {
        old_password: null,
        password: null,
        password_confirmation: null,
      },
    };
  },

  validations: {
    user: {
      old_password: { required },
      password: {
        required,
        minLength: minLength(6),
        containsUppercase: function(value) {
          return /[A-Z]/.test(value) === true;
        },
        containsNumber: function(value) {
          return /[0-9]/.test(value) === true;
        },
      },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
    },
  },

  methods: {
    handlePasswordChange() {
      this.$v.$touch();
      if (!this.$v.invalid && !this.loading) {
        this.loading = true;
        this.success = null;
        this.errors = [];
        this.error = null;
        this.$store
          .dispatch("auth/changeUserPassword", this.user)
          .then(() => {
            this.success = this.$t("profile.password_successful_updated");
            this.editing = false;
            this.loading = false;
            this.user.password = null;
            this.user.password_confirmation = null;
            this.$v.$reset();
          })
          .catch(err => {
            if (err.response.data.errors)
              this.errors = err.response.data.errors;
            if (err.response.data.message)
              this.error = err.response.data.message;
            this.loading = false;
          });
      }
    },

    regenerateCodes() {
      if (!this.loading2FA) {
        this.loading2FA = true;
        this.$store
          .dispatch("auth/regenerateRecoveryCodes")
          .then(({ codes }) => {
            this.recoveries = codes;
          })
          .finally(() => (this.loading2FA = false));
      }
    },

    disabled2FA() {
      if (!this.loading2FA) {
        this.loading2FA = true;
        this.$store
          .dispatch("auth/disableMFA")
          .finally(() => (this.loading2FA = false));
      }
    },
  },
};
</script>

<style scoped></style>
